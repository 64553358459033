import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "btn-white" }
const _hoisted_2 = { class: "btn-white__wrapper" }
const _hoisted_3 = { class: "btn-white__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_touch_ripple = _resolveComponent("touch-ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_touch_ripple, {
      color: "#859dff",
      opacity: 0.1,
      transition: "ease-out",
      duration: 300,
      "keep-last-ripple": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("a", {
          href: "/",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_inline_svg, {
                src: require('@/assets/svg/cards.svg')
              }, null, 8, ["src"])
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.isShare ? 'Сделать свой расклад' : 'Сделать новый расклад'), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["opacity"])
  ]))
}