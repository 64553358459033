
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInterface } from 'swiper';
import { EffectFade } from 'swiper';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';

import SocialIcon from '@/components/ui/SocialIcon.vue';
import BtnWhite from '@/components/ui/BtnWhite.vue';
import DivinationTabs from '@/components/ui/DivinationTabs.vue';
import InlineSvg from 'vue-inline-svg';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { store } from '@/store';
import { TarotMotion } from '@/modules/Tarot';

export default defineComponent({
  name: 'DivinationView',
  components: {
    SocialIcon,
    DivinationTabs,
    Swiper,
    SwiperSlide,
    BtnWhite,
    InlineSvg,
  },
  setup() {
    const route = useRoute();

    const isBlockBtn = computed(() => store.isBlockBtn);

    const isShare = computed(() => !!route.query?.share);
    const shareUrl = computed(() => new URL(`${window.location.href}?share=true`));
    const link: Ref<HTMLElement | null> = ref(null);
    const contentHeight = ref('auto');
    const prevBtn: Ref<HTMLElement | null> = ref(null);
    const isTablet = ref(window.matchMedia('(max-width: 1024px)').matches);
    const isCoverHeader = ref(false);
    const paddingTop = ref('unset');
    const hero: Ref<HTMLElement | null> = ref(null);
    const card = computed(() => store.getCards(String(route.params?.id)));
    const activeIndex = ref(0);
    const heroPaddingTop = ref('unset');
    const tabs: Ref<HTMLElement | null> = ref(null);
    const slider: Ref<SwiperInterface | null> = ref(null);
    const spread: Ref<HTMLElement | null> = ref(null);
    const isLastSlide = ref(false);
    const isFirstSlide = ref(true);
    const isLoading = computed(() => store.isLoading);

    const debounceOnResize = debounce(onResizeTabs, 200);

    const socialLinks = [
      {
        icon: 'vk',
        network: 'vk',
      },
      {
        icon: 'ok',
        network: 'odnoklassniki',
      },
      {
        icon: 'tg',
        network: 'telegram',
      },
    ];
    const divinationTabs = [
      {
        icon: 'heart',
        text: 'В отношениях',
      },
      {
        icon: 'heart',
        text: 'В работе',
      },
      {
        icon: 'heart',
        text: 'В финансах',
      },
    ];
    let timeline = gsap.timeline();

    watch(() => isLoading.value, (value) => {
      if (!value) {
        setAnimation();
      }
    });

    function changeSlide(index: number) {
      slider.value?.slideTo(index, 300);
    }

    function onSlideChange(swiper: SwiperInterface) {
      if (isTablet.value) return;

      const { activeIndex: active, slides } = swiper;
      const tabsChildren: HTMLCollection | undefined = tabs.value?.children;

      let prevBtnWidth = {
        width: 0,
        marginRight: 0,
      };

      if (prevBtn.value && !isTablet.value) {
        const { width, marginRight } = window.getComputedStyle(prevBtn.value);

        prevBtnWidth = {
          width: Number(width.replace('px', '')),
          marginRight: Number(marginRight.replace('px', '')),
        };
      }

      if (!tabsChildren) return;

      const element = tabsChildren[active] as HTMLElement;
      const calcX = -element.offsetLeft + prevBtnWidth.width + prevBtnWidth.marginRight;

      gsap.to(tabs.value, { x: calcX, ease: 'sine.inOut' });

      activeIndex.value = active;

      isLastSlide.value = (active + 1) === slides.length;
      isFirstSlide.value = !active;
    }

    function onSwiper(swiper: SwiperInterface) {
      slider.value = swiper;
    }

    function slideNext() {
      slider.value?.slideNext();
    }

    function slidePrev() {
      slider.value?.slidePrev();
    }

    function setPaddingTop() {
      if (isTablet.value) {
        heroPaddingTop.value = 'unset';

        return;
      }

      const marginTop = '3rem';
      const { height } = tabs.value?.getClientRects()[0] as DOMRect;

      heroPaddingTop.value = `calc(${height}px + ${marginTop})`;
    }

    function setAnimation() {
      timeline?.kill();

      const slideIndex = isTablet.value ? 1 : 0;

      const firstSlide = slider.value?.slides[slideIndex].children as HTMLCollection;
      const tabsChildren = tabs.value?.querySelectorAll('.divination-tab') as HTMLCollection | undefined;
      const spreadChildren = spread.value?.children as HTMLCollection;
      const btnSlider = hero.value?.querySelectorAll('.divination__btn-slider-wrapper') as HTMLCollection | undefined;
      const linkChildren = link.value?.children as HTMLCollection;

      if (!firstSlide || !tabsChildren || !spreadChildren || !btnSlider) return;

      timeline = gsap.timeline({ delay: 0.5 });

      timeline
        .to(tabsChildren, { opacity: 1, ease: 'sine.inOut', stagger: 0.2 })
        .to(firstSlide, {
          opacity: 1, ease: 'sine.inOut', stagger: 0.2, delay: 0.1,
        }, '<')
        .to(btnSlider, { opacity: 1, ease: 'sine.inOut', delay: 0.2 }, '<')
        .to(spreadChildren, {
          opacity: 1, ease: 'sine.inOut', delay: 0.3, stagger: 0.2,
        }, '<')
        .to(linkChildren, {
          opacity: 1, ease: 'sine.inOut', delay: 0.4, stagger: 0.2,
        }, '<');
    }

    function calcContentHeight() {
      if (isTablet.value && paddingTop.value !== 'unset') {
        contentHeight.value = paddingTop.value.replace('(', '(100vh - 6rem - ');
      } else {
        contentHeight.value = 'auto';
      }
    }

    function setInitialElement() {
      isCoverHeader.value = false;
      paddingTop.value = 'unset';
    }

    function checkCoverHeader() {
      const isDesktop = window.matchMedia('(min-width: 1440px)').matches;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { y }: any = hero.value?.getBoundingClientRect();
      const header = document.querySelector('header');
      const marginBottom = isDesktop ? '0px' : '2rem';
      let headerHeight = 0;

      if (header) {
        headerHeight = Number(window.getComputedStyle(header).height.replace('px', ''));
      }

      if (y < headerHeight || y <= 0) {
        isCoverHeader.value = true;
        paddingTop.value = `calc(${headerHeight}px + ${marginBottom})`;
      } else {
        isCoverHeader.value = false;
      }
    }

    function onResizeTabs() {
      isTablet.value = window.matchMedia('(max-width: 1024px)').matches;

      setInitialElement();

      nextTick(() => {
        checkCoverHeader();
        setPaddingTop();
        calcContentHeight();
        setAnimation();
      });

      let prevBtnWidth = {
        width: 0,
        marginRight: 0,
      };

      if (prevBtn.value && !isTablet.value) {
        const { width, marginRight } = window.getComputedStyle(prevBtn.value);

        prevBtnWidth = {
          width: Number(width.replace('px', '')),
          marginRight: Number(marginRight.replace('px', '')),
        };
      }

      if (!tabs.value) return;

      const tabsChildren = tabs.value.children;
      const element = tabsChildren[activeIndex.value] as HTMLElement;
      const calcX = -element.offsetLeft + prevBtnWidth.width + prevBtnWidth.marginRight;

      if (!tabsChildren) return;

      if (element) {
        gsap.set(tabs.value, { x: calcX });
      }

      slider.value?.update();
    }

    const beforeLeave = () => new Promise((resolve) => {
      store.setBlockBtn(true);

      timeline.timeScale(2).reverse().then(() => resolve({}));
    });

    onBeforeRouteLeave((_, __, next) => {
      beforeLeave().then(() => next());
      TarotMotion.cards.backToChoice();
    });

    onMounted(() => {
      store.setBlockBtn(false);

      setInitialElement();

      window.addEventListener('resize', debounceOnResize);

      setPaddingTop();

      setTimeout(() => {
        onResizeTabs();

        if (!isLoading.value) {
          setAnimation();
        }
      }, 300);
    });

    onBeforeUnmount(() => {
      timeline?.kill();

      window.removeEventListener('resize', debounceOnResize);
    });

    const close = async () => {
      store.setBlockBtn(true);

      beforeLeave().then(() => {
        store.setView('card', 'card');
      });
    };

    return {
      link,
      socialLinks,
      isShare,
      shareUrl,
      contentHeight,
      isBlockBtn,
      prevBtn,
      isTablet,
      hero,
      paddingTop,
      isCoverHeader,
      divinationTabs,
      isLastSlide,
      isFirstSlide,
      spread,
      tabs,
      activeIndex,
      heroPaddingTop,
      card,
      EffectFade,

      slidePrev,
      beforeLeave,
      close,
      onSlideChange,
      onSwiper,
      slideNext,
      changeSlide,
    };
  },
});
