
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';
import SplitType from 'split-type';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { TouchRipple } from 'vue-touch-ripple';

import { store } from '@/store';
import { TarotMotion } from '@/modules/Tarot';
import SocialIcon from '@/components/ui/SocialIcon.vue';
import router from '@/router';

export default defineComponent({
  name: 'CardView',
  components: { SocialIcon, TouchRipple },
  setup() {
    const route = useRoute();

    const isBlockBtn = computed(() => store.isBlockBtn);
    const card = computed(() => store.getCards(String(route.params?.id)));
    const shareUrl = computed(() => new URL(`${window.location.href}?share=true`));

    const isCoverHeader = ref(false);
    const paddingTop = ref('unset');
    const hero: Ref<HTMLElement | null> = ref(null);
    const title: Ref<HTMLElement | null> = ref(null);
    const subtitle: Ref<HTMLElement | null> = ref(null);
    const tags: Ref<HTMLElement | null> = ref(null);
    const description: Ref<HTMLElement | null> = ref(null);
    const mainBtn: Ref<HTMLElement | null> = ref(null);
    const socialsWrapper: Ref<HTMLElement | null> = ref(null);
    const controlsWrapper: Ref<HTMLElement | null> = ref(null);
    const isLoading = computed(() => store.isLoading);

    const socialLinks = [
      {
        icon: 'vk',
        network: 'vk',
      },
      {
        icon: 'ok',
        network: 'odnoklassniki',
      },
      {
        icon: 'tg',
        network: 'telegram',
      },
    ];

    const debouncedOnResizeWindow = debounce(onResizeWindow, 200);

    const subtitleSplitType: Array<any> = [];

    let timeline = gsap.timeline();

    watch(() => isLoading.value, (value) => {
      setInitialElement();

      if (!value) {
        setAnimation();

        nextTick(() => {
          checkCoverHeader();
        });
      }
    });

    watch(() => card.value, (value) => {
      if (value?.id) {
        new Promise<void>((resolve) => {
          setTimeout(() => {
            if (title.value) {
              new SplitType(title.value, { types: 'words' });

              gsap.set(title.value, { opacity: 1 });
            }

            if (description.value) {
              const children = description.value?.children as HTMLCollection;

              for (let i = 0; i < children.length; i += 1) {
                const item = children[i] as HTMLElement;

                subtitleSplitType.push(new SplitType(
                  item,
                  { types: 'lines', lineClass: 'card-line' },
                ));
              }
            }
            resolve();
          }, 100);
        }).then(() => {
          if (!isLoading.value) {
            setAnimation();
          }
        });
      }
    }, { immediate: true });

    function setInitialElement() {
      isCoverHeader.value = false;
      paddingTop.value = 'unset';
    }

    function checkCoverHeader() {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { y }: any = hero.value?.getBoundingClientRect();
      const header = document.querySelector('header');
      let headerHeight = 0;

      if (header) {
        headerHeight = Number(window.getComputedStyle(header).height.replace('px', ''));
      }

      if (y < headerHeight || y <= 0) {
        isCoverHeader.value = true;
        paddingTop.value = `calc(${headerHeight}px + 2rem)`;
      } else {
        isCoverHeader.value = false;
      }
    }

    function onResizeWindow() {
      setInitialElement();

      nextTick(() => {
        checkCoverHeader();
      });

      for (let i = 0; i < subtitleSplitType.length; i += 1) {
        subtitleSplitType[i]?.split();
      }

      const element = description.value;
      const lines = element ? element.querySelectorAll('.card-line') : [];

      gsap.set(lines, { opacity: 1, y: 0 });
    }

    function setAnimation() {
      timeline?.kill();

      const element = description.value;
      const lines = element ? element.querySelectorAll('.card-line') : [];
      const tagItems = tags.value?.children as HTMLCollection;
      const titleItems = title.value?.children as HTMLCollection;
      const socialsItems = socialsWrapper.value?.children as HTMLCollection;
      const controlsItems = controlsWrapper.value?.children as HTMLCollection;

      gsap.set(description.value, { opacity: 1 });

      timeline = gsap.timeline({
        delay: 0.3,
      });

      timeline
        .to(subtitle.value, { opacity: 1, y: 0, ease: 'sine.out' })
        .to(titleItems, {
          opacity: 1, y: 0, stagger: 0.2, ease: 'sine.out', willChange: 'transform, opacity',
        }, '<')
        .to(tagItems, {
          opacity: 1, stagger: 0.1, ease: 'sine.out', delay: 0.2,
        }, '<')
        .to(lines, {
          opacity: 1, y: 0, stagger: 0.07, ease: 'sine.out',
        }, '<')
        .to(mainBtn.value, { opacity: 1, ease: 'sine.out' }, '<')
        .to(socialsItems, { opacity: 1, stagger: 0.07, ease: 'sine.out' }, '<')
        .to(controlsItems, { opacity: 1, stagger: 0.07, ease: 'sine.out' }, '<');
    }

    const beforeLeave = () => new Promise((resolve) => {
      store.setBlockBtn(true);

      timeline.timeScale(2).reverse().then(() => {
        resolve({});
      });
    });

    onBeforeRouteLeave(async (_, __, next) => {
      TarotMotion.cards.backToChoice();

      await beforeLeave();

      next();
    });

    onMounted(() => {
      store.setBlockBtn(false);

      setInitialElement();

      nextTick(() => {
        checkCoverHeader();
      });

      window.addEventListener('resize', debouncedOnResizeWindow);
    });

    onBeforeUnmount(() => {
      timeline?.kill();

      window.removeEventListener('resize', debouncedOnResizeWindow);
    });

    return {
      router,
      socialsWrapper,
      controlsWrapper,
      store,
      isBlockBtn,
      hero,
      paddingTop,
      isCoverHeader,
      card,
      title,
      subtitle,
      description,
      tags,
      mainBtn,
      socialLinks,
      shareUrl,

      beforeLeave,
    };
  },
});
