import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "social-icon" }
const _hoisted_2 = { class: "social-icon__wrapper" }
const _hoisted_3 = { class: "social-icon__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_touch_ripple = _resolveComponent("touch-ripple")!
  const _component_share_network = _resolveComponent("share-network")!

  return (_openBlock(), _createBlock(_component_share_network, {
    network: _ctx.network,
    url: _ctx.href,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_touch_ripple, {
          color: "#859dff",
          opacity: 0.1,
          transition: "ease-out",
          duration: 300,
          "keep-last-ripple": false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_inline_svg, {
                  src: require(`@/assets/svg/${_ctx.icon}.svg`)
                }, null, 8, ["src"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["opacity"])
      ])
    ]),
    _: 1
  }, 8, ["network", "url", "title"]))
}