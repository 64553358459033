
import {
  defineComponent, onBeforeUnmount, onMounted, ref,
} from 'vue';
import { gsap } from 'gsap';
import { TarotMotion } from '@/modules/Tarot';
import { delay } from '@/modules/Tarot/utils';
import AnimatedValue from '@/modules/AnimatedValue';
import { store } from '@/store';

import AppHeader from '@/components/general/AppHeader.vue';
import RabbitsStore from '@/modules/Tarot/RabbitsStore';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    AppHeader,
  },
  setup() {
    const motionEl = ref<HTMLElement | null>(null);
    const main = ref<HTMLElement | null>(null);
    const height = ref('100%');

    onMounted(async () => {
      const preloader: HTMLElement | null | undefined = document.getElementById('app-preloader');
      const preloaderContent: HTMLElement | null | undefined = preloader?.querySelector('.app-preloader__content');
      const preloaderBackground: HTMLElement | null | undefined = preloader?.querySelector('.app-preloader__background');
      const route = useRoute();

      if (!preloader || !preloaderContent || !preloaderBackground) {
        throw new Error('No preloader element found');
      }

      const animation = new AnimatedValue();

      const updateHandler = (value: number) => {
        preloaderContent.innerHTML = `${String(Math.ceil(value))}%`;
      };

      const finishHandler = async () => {
        animation.removeListener('update', updateHandler);
        animation.removeListener('finish', finishHandler);

        const tl = gsap.timeline();

        const hasId = !!route.params.id;
        if (hasId) {
          store.setView('spread', 'home');
          await TarotMotion.cards.prepareForDetail().then(() => {
            RabbitsStore.update();
          });
        }

        tl.set(preloaderContent, {
          animation: 'none', opacity: 1, y: 0, willChange: 'opacity, transform',
        });
        tl.to(preloaderContent, {
          y: -75, duration: 0.85, opacity: 0, ease: 'power2.in',
        });
        tl.add(() => {
          preloader.remove();
          store.finishLoading();
        });
      };

      animation.on('update', updateHandler);
      animation.on('finish', finishHandler);

      const { appStartTime } = window;
      const now = new Date();
      const diff = now.getTime() - appStartTime.getTime();
      const $delay = 1000 - diff;

      if ($delay > 0) await delay($delay);
      animation.to(10);

      const preload = [
        TarotMotion.preload().then(() => animation.to(animation.value + 10)),
        fetch('/cards.json').then((res) => {
          animation.to(animation.value + 10);

          return res.json();
        }).then((cards) => {
          store.setCards(cards);
        }),
      ];

      await Promise.all(preload);

      await TarotMotion.create(motionEl.value);
      animation.to(40);

      const showScene = () => {
        const tl = gsap.timeline();

        tl.set(preloaderContent, { willChange: 'auto' });
        tl.to(preloaderBackground, {
          opacity: 0,
          duration: 1,
          onComplete: () => {
            gsap.set(preloaderBackground, { willChange: 'auto' });
          },
        });

        const { lightRadiusMultiplier, cloudReposition } = TarotMotion.background.materialUniforms;

        tl.to(cloudReposition, { value: 0, duration: 5, ease: 'elastic.out(0.4, 0.8)' }, '<');
        tl.to(lightRadiusMultiplier, { value: 1, duration: 5, ease: 'elastic.out(0.6, 0.4)' }, '<');
      };

      showScene();
      RabbitsStore.init(route);
      await RabbitsStore.preload(() => {
        animation.to(animation.value + 15);
      });

      TarotMotion.cards.setMainTexture();

      animation.to(100);
    });

    onBeforeUnmount(() => {
      TarotMotion.kill();
    });

    return {
      height,
      main,
      motionEl,
      store,
    };
  },
});
