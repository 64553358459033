import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__wrapper" }
const _hoisted_2 = { class: "header__logo" }
const _hoisted_3 = { class: "header__logos" }
const _hoisted_4 = {
  href: "https://paraweb.me/",
  target: "_blank",
  referrerpolicy: "no-referrer"
}
const _hoisted_5 = { class: "header__logo _paraweb" }
const _hoisted_6 = {
  href: "https://guap.ru/",
  target: "_blank",
  referrerpolicy: "no-referrer"
}
const _hoisted_7 = { class: "header__logo _goup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("header", {
    id: "header",
    class: _normalizeClass(["header", { '_disabled': _ctx.isBlockBtn }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: "/",
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["prevent"]))
      }, [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_inline_svg, {
            class: _normalizeClass(["svg-logo", { 'active': _ctx.isSvgAnimation }]),
            src: require('@/assets/svg/logo.svg'),
            name: "logo"
          }, null, 8, ["class", "src"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, {
              class: _normalizeClass(["svg-logo", { 'active': _ctx.isSvgAnimation }]),
              src: require('@/assets/svg/paraweb-logo.svg'),
              name: "paraweb-logo"
            }, null, 8, ["class", "src"])
          ])
        ]),
        _createElementVNode("a", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, {
              class: _normalizeClass(["svg-logo", { 'active': _ctx.isSvgAnimation }]),
              src: require('@/assets/svg/guap_logo.svg'),
              name: "goup-logo"
            }, null, 8, ["class", "src"])
          ])
        ])
      ])
    ])
  ], 2))
}