import { reactive } from 'vue';

export type RawCard = {
  id: string
  arcan: string
  name: string
  keywords: string
  description: string
  relationshipKeywords: string
  relationship: string
  careerKeywords: string
  career: string
  financeKeywords: string
  finance: string
}

export const store = reactive<{
  cards: Array<RawCard>
  homeView: string
  cardView: string
  setView: (value: string, key: string) => void
  setCards: (cards: Array<RawCard>) => void
  getCards: (id: string) => RawCard | undefined
  isLoading: boolean
  isBlockBtn: boolean
  setBlockBtn: (flag: boolean) => void
  finishLoading: () => void
    }>({
      cards: [],
      isLoading: true,
      homeView: 'home',
      cardView: 'card',
      isBlockBtn: false,

      setBlockBtn(flag) {
        this.isBlockBtn = flag;
      },
      setCards(cards) {
        this.cards = cards;
      },
      getCards(id) {
        return this.cards.find(({ id: cardId }) => cardId === id);
      },
      finishLoading() {
        this.isLoading = false;
      },
      setView(value: string, key: string) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[`${key}View`] = value;
      },
    });
